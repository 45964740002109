import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';

import { Fragment } from 'react';


import logoPurple from './../../assets/logo-on-purple.png';
import logoWhite from './../../assets/logo-on-white.png';
import checkIcon from './../../assets/check.svg';
import showIcon from './../../assets/show.svg';
import hideIcon from './../../assets/hide.svg';
import expiredIcon from './../../assets/expired-link.svg';
import errorIcon from './../../assets/error-505.svg';
import closeIcon from './../../assets/close.svg';

import { useSearchParams } from 'react-router-dom';
import { checkEmail } from '../../utils/helpers/data-helper';
import NotFound from '../not-found/not-found.component';

const defaultFormFields = {
  password: '', 
  confirmPassword: '',
};

const defaultExpiredFields = {
  email: ''
};

function ResetPassword() {

  const [searchParams] = useSearchParams();
  const userId = searchParams.get('id');
  const token = searchParams.get('token').replace(/ /g,"+");
  const [loadingMessage, setLoadingMessage] = useState("Resetting your password");
  const [isLoading, setIsLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [isReady, setIsReady] = useState(false);

  // token validation on load 
  const [isExpiredToken, setIsExpiredToken] = useState(false);
  useEffect(() => {

    const doVerifyToken = async() => {
      try {
        const doVerifyToken = await fetch(process.env.REACT_APP_API_URL + 'mobile/user/Verify-Reset-Password-Token', {
          method: 'POST',
          body: JSON.stringify({userId: userId, token: token}),                                              
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        const jsonData = await doVerifyToken.json();
        console.log(jsonData);

        if(jsonData.code === 401 ) {
          setIsExpiredToken(true); 
        }

        setIsReady(true);

      } catch(error) {
        console.log(error);
        handleShow();
      }
    };

    doVerifyToken();
    
  },[userId, token]); 

  
  // sending reset password token 
  const [sentResetToken, setSentResetToken] = useState(false);
  const [expiredFields, setExpiredFields] = useState(defaultExpiredFields);
  const { email } = expiredFields;

  const handleChangeExpired = (event) => {
    const { name, value } = event.target;
    setExpiredFields({...expiredFields, [name]: value});
  };

  
  const sendConfirmationLink = async(event) => {
    event.preventDefault();
    setLoadingMessage("Sending...");
    if(checkEmail(email)) {
   
      setIsLoading(true);

      try {
        const doVerifyToken = await fetch(process.env.REACT_APP_API_URL + 'mobile/user/Forgot-Password', {
          method: 'POST',
          body: JSON.stringify({Email: email.trim(), device: "web"}),                                              
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        const jsonData = await doVerifyToken.json();
        if(jsonData.data && jsonData.data.response.code === 200) {
          setSentResetToken(true);
        }

        setIsLoading(false);
      } catch(error) {
     
        handleShow();
        setIsLoading(false);
      }


    } else {
      console.log('failed email');
    }
  };


  // do reset password 
  const [passwordType, setPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  const [isSent, setIsSent] = useState(false);

  const [formFields, setFormFields] = useState(defaultFormFields);
  const { password, confirmPassword } = formFields;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormFields({...formFields, [name]: value});
  };

  const doReset = async(event) => {
    event.preventDefault();

    setLoadingMessage("Resetting your password");
    
    if(password.length >= 6 && password === confirmPassword ) {
      setIsLoading(true);
      try {

        //throw new Error('error');
        const doReset = await fetch(process.env.REACT_APP_API_URL + '/mobile/user/Reset-Password', {
          method: 'POST',
          body: JSON.stringify({userId: userId, token: token, newPassword: password}),                                              
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const jsonData = await doReset.json();
        //console.log(jsonData);

        setIsLoading(false);

        if(jsonData.data && jsonData.data.response.code === 200) {
          setIsSent(true);
        } else {
          setIsSent(false);
        }
       

      } catch(error) {
        console.log(error);
        setIsLoading(false);
        handleShow();
      }
    

    } else {
      console.log('there are errors for reset');
    }

  };


  const disableResetButton = () => {
    if(password.length >= 6 && password === confirmPassword ) {
      return false;
    } else {
      return true;
    }
  };

  if(userId && token) {
    
    return (
    
      <Fragment>
  
        <Container className='reset-container-fluid' fluid>
  
          <Row className='reset-row'>
            <Col>
  
              <div className="reset-container-wrapper">
                <div className="reset-container">
  
                  { isReady && 
                    <Fragment>
                      <img src={logoPurple} className="logo logo-desktop" alt={`Eagle quote logo`} />
                    </Fragment>
                  }
  
                  { isReady && !isExpiredToken && !sentResetToken && !isSent && !isLoading &&
                  <div className="form-container">
                  
                    <img src={logoWhite} className="logo logo-mobile" alt={`Eagle quote logo`} />
                    <h1>Reset Password</h1>
                    <p className='tagline'>Please enter a new password</p>
                    <form onSubmit={doReset}>
                      <Form.Group className="form-group" controlId="password">
                        <Form.Label>New Password</Form.Label>
                        <div className="wrap-input">
                          { passwordType && passwordType === 'password' &&
                            <img src={showIcon} alt={`Show Password`} onClick={() => setPasswordType('text')} />
                          }
                          { passwordType && passwordType === 'text' &&
                            <img src={hideIcon} alt={`Hide Password`} className="hide-eye-icon" onClick={() => setPasswordType('password')} />
                          }
                          <Form.Control type={passwordType} className='password-type' name="password" onChange={handleChange} />
                        </div>
  
                        { password.length > 0 && password.length < 6 &&
                          <p className='message error-message'>Oops! That's a little short of 6 characters</p>
                        }
  
                        { password.length === 0 &&
                          <p className='message'>Must contain at least 6 characters</p>
                        }
  
                      </Form.Group>
  
                      <Form.Group className="form-group last-form-group" controlId="confirmPassword">
                        <Form.Label>Confirm Password</Form.Label>
                        <div className="wrap-input">
                          { confirmPasswordType && confirmPasswordType === 'password' &&
                            <img src={showIcon} alt={`Show Password`} onClick={() => setConfirmPasswordType('text')} />
                          }
                          { confirmPasswordType && confirmPasswordType === 'text' &&
                            <img src={hideIcon} alt={`Hide Password`} className="hide-eye-icon" onClick={() => setConfirmPasswordType('password')} />                                                                                                     
                          }
                          <Form.Control type={confirmPasswordType} className='password-type' name="confirmPassword" onChange={handleChange} />
                        </div>
  
                        { confirmPassword.length > 0 && password.length > 0 && password !== confirmPassword &&
                          <p className='message error-message'>Your password doesn't match</p>
                        }
                      </Form.Group>
  
                      <Button className='reset-button' disabled={disableResetButton()} type="submit">
                        Reset Password
                      </Button>
  
                    </form>
                  
                  </div>
                  }
  
                  { isReady && isExpiredToken && !sentResetToken && !isSent && !isLoading &&
                  <div className="form-container link-expired-container">
                    
                    <img src={expiredIcon} alt={`expired icon`} />
                    <h1>Link is expired</h1>
                    <p>Please enter your email address to generate <br /> a new “Password Reset” link</p>
                    <form onSubmit={sendConfirmationLink}>
                      
                      <Form.Group className="form-group email-group" controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <div className="wrap-input">
                          <Form.Control type="text" name="email" onChange={handleChangeExpired} />
                        </div>
                        { email.length > 0 && !checkEmail(email) &&
                          <p className='message error-message'>Please enter a valid email address</p>
                        }
                      </Form.Group>
  
                      <Button className='reset-button' type="submit">
                        Continue
                      </Button>
              
                    </form>
                  
                  </div>
                  }
                  
                  { isSent && !isLoading &&
                  <div className="confirmation-container">
                    <img src={checkIcon} alt={`Confirmation icon`} />
                    <h2>Password Reset</h2>
                    <p>Your password has been changed. You can close this window. Please return to the app and login with your new password.</p>                             
                  </div>
                  }
  
                  { sentResetToken && !isLoading &&
                  <div className="confirmation-container-two">
                    <img src={checkIcon} alt={`Confirmation icon`} />
                    <h2>Check your inbox</h2>
                    <p>Click on the link that we just sent over to <br /> <span className='email-highlight'>{email}</span> to get you back on track</p>                                          
                    <p>Didn't receive the email? Sorry about that, <br /> <span className='resend-highlight' onClick={sendConfirmationLink}>Click here to resend</span></p>
                  </div>
                  }
                
                  { isLoading &&
                  <div className="resetting-container">
                  
                    <div className="loader-2 circle-loading-2"><span></span></div>
  
                    <h2>{loadingMessage}</h2>
                  </div>
                  }
  
  
                </div>
              </div>
            
            </Col>
          </Row>
        
        </Container>
  
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          animation={false}
          centered
          dialogClassName="modal-error"
        >
        
          <Modal.Body>
            <div className="modal-container">
              <img src={errorIcon} alt={`Error Icon`} />
              <h3>This is embarassing</h3>
              <p>Something went wrong. Please try again.</p>
              <p className='modal-close' onClick={handleClose}>
                <img src={closeIcon} alt={`Close Icon`} />
                <span>Close</span>
              </p>
            </div>
          </Modal.Body>
         
        </Modal>
  
      </Fragment>
  
  
    );

  } else {
    return(
      <NotFound />
    )
  }

  


}

export default ResetPassword;
